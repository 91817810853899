import { LoadChildrenCallback, RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { CognitoUserLoggedIn } from '@auth/guards/cognito-user-logged-in.service';
import { UserType } from '@generated/graphql';
import { AuthGuard } from '@auth/guards/auth.guard';
import { SuperadminGuard } from '@app/auth/guards/superadmin.guard';
import { PipelineAuthGuard } from '@app/pages/pipeline/guards/pipeline-auth.guard';
import { ModuleTypes, getLazyModule } from '@app/pages/lazy-modules';
import { FeToggleGuard } from '@app/shared/guards/fe-toggle.guard';
import { RdrFeature } from '@app/ui/services/feature-toggle.service';
import { SalesDirectorGuard } from '@app/pages/users/guards/sales-director.guard';
import { SearcherGuard } from '@app/pages/my-queue/guards/searcher.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('../pages/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'clients',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        loadChildren: () => import('../pages/clients/clients.module').then((m) => m.ClientsModule),
      },
      {
        path: 'call',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        data: {
          roles: [UserType.SalesAgent, UserType.SalesTeamLead],
        },
        loadComponent: () =>
          import('../calls/pages/call-page/call-page.component').then((m) => m.CallPageComponent),
      },
      {
        path: 'miles-inventory',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        data: {
          roles: [UserType.Administrator, UserType.TicketingAgent, UserType.Accounting],
        },
        loadChildren: () =>
          import('../pages/miles-inventory/miles-inventory.module').then(
            (m) => m.MilesInventoryModule
          ),
      },
      {
        path: 'users',
        canActivate: [CognitoUserLoggedIn, AuthGuard, SalesDirectorGuard], // todo replace guard with fe toggle
        data: {
          roles: [UserType.Administrator, UserType.SalesTeamLead],
        },
        loadChildren: () => import('../pages/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'my-queue',
        canActivate: [CognitoUserLoggedIn, SearcherGuard, AuthGuard],
        data: {
          roles: [UserType.Searcher, UserType.Administrator],
        },
        loadChildren: () =>
          import('../pages/my-queue/my-queue.module').then((m) => m.MyQueueModule),
      },
      {
        path: 'upcoming-flights',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        data: {
          roles: [
            UserType.Administrator,
            UserType.TicketingAgent,
            UserType.SalesAgent,
            UserType.SalesTeamLead,
          ],
        },
        loadChildren: () =>
          import('../pages/upcoming-flights/upcoming-flights.module').then(
            (m) => m.UpcomingFlightsModule
          ),
      },
      {
        path: 'orders',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        data: {
          roles: [
            UserType.Administrator,
            UserType.TicketingAgent,
            UserType.SalesAgent,
            UserType.SalesTeamLead,
            UserType.Accounting,
          ],
        },
        loadChildren: () => import('../pages/orders/orders.module').then((m) => m.OrdersV2Module),
      },
      {
        path: 'costs',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        data: {
          roles: [UserType.Administrator, UserType.Accounting],
        },
        loadChildren: () => import('../pages/costs/costs.module').then((m) => m.CostsModule),
      },
      {
        path: 'invoices',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        data: {
          roles: [UserType.Administrator, UserType.Accounting, UserType.TicketingAgent],
        },
        loadChildren: () =>
          import('../pages/invoices/invoices.module').then((m) => m.InvoicesModule),
      },
      {
        path: 'profile',
        canActivate: [CognitoUserLoggedIn, AuthGuard, SuperadminGuard],
        loadChildren: () => import('../pages/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'team-dashboard',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        data: {
          roles: [UserType.SalesAgent, UserType.SalesTeamLead],
        },
        loadChildren: () =>
          import('../pages/team-dashboard/team-dashboard.module').then(
            (m) => m.TeamDashboardModule
          ),
      },
      {
        path: 'pipeline',
        canActivate: [CognitoUserLoggedIn, PipelineAuthGuard, AuthGuard],
        data: {
          roles: [UserType.SalesAgent, UserType.SalesTeamLead, UserType.TicketingAgent],
        },
        loadChildren: getLazyModule(ModuleTypes.Pipeline) as LoadChildrenCallback,
      },
      {
        path: 'airline-classes',
        canActivate: [CognitoUserLoggedIn, AuthGuard, FeToggleGuard],
        data: {
          feature: RdrFeature.airline_classes_administrator,
        },
        loadChildren: () =>
          import('../pages/airline-classes/airline-classes.module').then(
            (m) => m.AirlineClassesModule
          ),
      },
      {
        path: 'miles-programs',
        canActivate: [CognitoUserLoggedIn, AuthGuard, FeToggleGuard],
        data: {
          feature: RdrFeature.edit_miles_program_price,
        },
        loadChildren: () =>
          import('../pages/miles-programs/miles-programs.module').then(
            (m) => m.MilesProgramsModule
          ),
      },
      {
        path: 'insights',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        data: {
          roles: [UserType.SalesTeamLead],
        },
        loadChildren: () =>
          import('../pages/insights/insights.module').then((m) => m.InsightsModule),
      },
      {
        path: 'support-requests',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        data: {
          roles: [UserType.SalesAgent, UserType.SalesTeamLead, UserType.TicketingAgent],
        },
        loadChildren: getLazyModule(ModuleTypes.SupportRequests) as LoadChildrenCallback,
      },
      {
        path: 'bookings',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        data: {
          roles: [UserType.Accounting, UserType.Administrator],
        },
        loadChildren: () =>
          import('../pages/bookings/bookings.module').then((m) => m.BookingsModule),
      },
      {
        path: 'payments',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        data: {
          roles: [UserType.Accounting, UserType.Administrator],
        },
        loadChildren: () =>
          import('../pages/payments/payments.module').then((m) => m.PaymentsModule),
      },
      {
        path: 'leads',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        data: {
          roles: [UserType.SalesAgent, UserType.SalesTeamLead],
        },
        loadChildren: () => import('../pages/leads/leads.module').then((m) => m.LeadsModule),
      },
      {
        path: 'flight-requests',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        loadChildren: () =>
          import('../pages/flight-requests/flight-requests.module').then(
            (m) => m.FlightRequestsModule
          ),
      },
      {
        path: 'search-flights',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        loadChildren: () => import('../pages/search/search.module').then((m) => m.SearchModule),
      },
      {
        path: 'trips',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        loadChildren: () => import('../pages/trips/trips.module').then((m) => m.TripsModule),
      },
      {
        path: 'change-requests',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        loadChildren: () =>
          import('../pages/change-requests/change-requests.module').then(
            (m) => m.ChangeRequestsModule
          ),
      },
      {
        path: 'miles-transfers',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        data: {
          roles: [UserType.Accounting, UserType.TicketingAgent, UserType.Administrator],
        },
        loadChildren: () =>
          import('../pages/miles-transfers/miles-transfers.module').then(
            (m) => m.MilesTransfersModule
          ),
      },
      {
        path: 'pnrs',
        canActivate: [CognitoUserLoggedIn, AuthGuard],
        data: {
          roles: [
            UserType.SalesAgent,
            UserType.SalesTeamLead,
            UserType.TicketingAgent,
            UserType.Accounting,
            UserType.Administrator,
            UserType.Searcher,
          ],
        },
        loadChildren: () => import('../pages/pnrs/pnrs.module').then((m) => m.PnrsModule),
      },
      // todo depricated redirects
      {
        path: 'root-orders',
        redirectTo: 'orders',
      },
      {
        path: 'sales/leads',
        redirectTo: 'leads',
      },
      {
        path: 'sales/flight-requests',
        redirectTo: 'flight-requests',
      },
      {
        path: 'sales/clients',
        redirectTo: 'clients',
      },
      {
        path: 'sales/upcoming-flights',
        redirectTo: 'upcoming-flights',
      },
      {
        path: 'sales/support-requests',
        redirectTo: 'support-requests',
      },
      {
        path: 'searcher/my-queue',
        redirectTo: 'my-queue',
      },
      {
        path: 'accounting/bookings',
        redirectTo: 'bookings',
      },
      {
        path: 'accounting/payments',
        redirectTo: 'payments',
      },
    ],
  },
  {
    path: 'contest',
    canActivate: [CognitoUserLoggedIn, AuthGuard],
    data: {
      roles: [UserType.SalesAgent, UserType.SalesTeamLead],
    },
    loadChildren: () => import('../pages/contest/contest.module').then((m) => m.ContestModule),
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
