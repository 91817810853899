<card
  *ngIf="cost"
  class="d-flex booking-cost v2-p-3 h-100 position-relative"
  [attr.cost-id]="cost.id"
>
  <button-icon-v2
    *ngIf="!readonly || (isTicketing && !readonlyForTicketing)"
    (click)="showModal(modalTemplate); $event.stopPropagation()"
    key="edit-pen"
    size="md"
    class="position-absolute edit"
    data-id="edit-cost"
  ></button-icon-v2>

  <div class="d-flex v2-mb-1 align-items-center">
    <typography-v2 class="v2-mr-3" look="head" dataId="amount">
      {{ (order?.totalPassengers ? cost.unitAmount : cost.amount) | rdrCurrency }}
    </typography-v2>
    <button-icon-v2
      *ngIf="cost.passenger"
      class="v2-mr-2"
      key="user-v-2"
      size="md"
      color="var(--color-black)"
      [tooltip]="cost.passenger.fullName"
      placement="top"
      data-id="pax"
    >
    </button-icon-v2>

    <button-icon-v2
      key="sticky-note"
      size="md"
      [tooltip]="cost.note"
      *ngIf="cost.note"
      data-id="note"
      class="v2-mr-2"
    ></button-icon-v2>

    <ng-container *showForRole="UserType.Accounting">
      <div *ngIf="cost.invoices?.edges?.length" class="d-flex position-relative">
        <button-icon-v2
          (click)="showedInvoices = true"
          key="file-v-2"
          size="md"
          tooltip="Invoices"
        ></button-icon-v2>
        <context-menu-v2
          *ngIf="showedInvoices"
          (clickOutside)="showedInvoices = false"
          class="invoices d-flex flex-column v2-p-2 position-absolute"
        >
          <a
            *ngFor="let invoice of cost.invoices?.edges | extractNodes; let first = first"
            routerLink="/invoices/{{ invoice.id }}"
            [ngClass]="{ 'v2-mt-2': !first }"
            ><ng-container *ngIf="!first">,&nbsp;</ng-container>Invoice #{{ invoice.id }} {{
            invoice.amount | rdrCurrency }}</a
          >
        </context-menu-v2>
      </div>
    </ng-container>
  </div>
  <div class="d-flex align-items-center flex-1 w-100">
    <typography-v2
      [tooltip]="isHotelCost ? tooltipTemplate : undefined"
      placement="top"
      data-id="type"
      >{{ cost.type | label }}</typography-v2
    >

    <ng-container *ngIf="cost.perType">
      <span class="v2-mx-3">&middot;</span>
      <typography-v2 tooltip="Per type" placement="top" data-id="per-type"
        >{{ cost.perType | label }}</typography-v2
      >
    </ng-container>

    <ng-container *showForRole="[UserType.TicketingAgent, UserType.Accounting]">
      <ng-container *ngIf="cost.revenueSupplier; else supplierFromBookingTemplate">
        <ng-container *ngIf="cost.revenueSupplier?.name">
          <span class="v2-mx-3">&middot;</span>
          <typography-v2 dataId="supplier">{{ cost.revenueSupplier.name }}</typography-v2>
        </ng-container>
      </ng-container>

      <ng-template #supplierFromBookingTemplate>
        <ng-container *ngIf="supplierFromBooking; else milesSupplier">
          <span class="v2-mx-3">&middot;</span>
          <typography-v2 dataId="supplier">{{ supplierFromBooking }}</typography-v2>
        </ng-container>

        <ng-template #milesSupplier>
          <ng-container *ngIf="cost.milesSupplier?.name">
            <span class="v2-mx-3">&middot;</span>
            <typography-v2 class="supplier-name" dataId="supplier"
              >{{ cost.milesSupplier?.name }}</typography-v2
            >
          </ng-container>
        </ng-template>
      </ng-template>

      <payment-method-v2 [cost]="cost"></payment-method-v2>
    </ng-container>
  </div>
</card>

<ng-template #modalTemplate>
  <modal-v2 header="Edit Cost">
    <order-booking-cost-form
      [order]="order"
      [orderId]="orderId"
      [bookingType]="bookingType"
      [data]="cost"
      [readonly]="readonly"
      [withDirect]="withDirect"
      (deleteEntity)="costDeleted.next($event)"
      (formSubmit)="onCostUpdated($event.data)"
    ></order-booking-cost-form>
  </modal-v2>
</ng-template>

<ng-template #tooltipTemplate>
  <typography-v2 *ngIf="cost?.hotelName" look="small"> {{ cost?.hotelName }} </typography-v2>
  <ng-container *ngIf="cost?.hotelNights">
    <br />
    <typography-v2 look="small"> {{ cost?.hotelNights }} night(s) </typography-v2>
  </ng-container>
</ng-template>
