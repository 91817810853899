import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventService } from './services/event.service';
import { ReactiveFormsModule } from '@angular/forms';
import { RadarEditableFocusDirective } from './directives/radar-editable-focus.directive';
import { RadarOnEnterDirective } from './directives/radar-on-enter.directive';
import { RadarOnEscapeDirective } from './directives/radar-on-escape.directive';
import { PageHeaderComponent } from '@app/shared/components/page-header/page-header.component';
import { UiModule } from '@app/ui/ui.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BookingTypeBadgeComponent } from './components/bookings/booking-type-badge/booking-type-badge.component';
import { AnchorNavigationComponent } from './components/anchor-navigation/anchor-navigation.component';
import { AnchorItemComponent } from './components/anchor-item/anchor-item.component';
import { BookingStatusBadgeComponent } from '@shared/components/bookings/booking-status-badge/booking-status-badge.component';
import { ShortFlightInfoComponent } from './components/short-flight-info/short-flight-info.component';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';
import { RouterModule } from '@angular/router';
import { RightPaneTabsComponent } from './components/right-pane-tabs/right-pane-tabs.component';
import { HistoryComponent } from './components/history/history.component';
import { TeamdeskLinkComponent } from './components/teamdesk-link/teamdesk-link.component';
import { EmailMessagesComponent } from './components/email-messages/email-messages.component';
import { EmailMessageComponent } from './components/email-message/email-message.component';
import { PipesModule } from '@ui/pipes/pipes.module';
import { InvoiceNotesComponent } from './components/notes/invoice-notes.component';
import { LeadNotesComponent } from './components/notes/lead-notes.component';
import { MilesAccountNotesComponent } from './components/notes/miles-account-notes.component copy';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CostsTableCellComponent } from './components/costs-table-cell/costs-table-cell.component';
import { SupportRequestsSectionComponent } from './components/support-requests-section/support-requests-section.component';
import { FormButtonStateDirective } from './directives/form-button-state.directive';
import { CommunicationContainerComponent } from './components/communication-container/communication-container.component';
import { CommunicationContainerNotesComponent } from './components/communication-container-notes/communication-container-notes.component';
import { UiV2Module } from '@app/ui-v2/ui-v2.module';
import { LessThenZeroPipe } from './pipes/less-then-zero.pipe';
import { ClientCardPreviewComponent } from './components/client-card-preview/client-card-preview.component';
import { UserAssignButtonComponent } from './components/user-assign-button/user-assign-button.component';
import { GroupedUsersFilterPipe } from './components/user-assign-button/grouped-users-filter.pipe';
import { BookingTypeSelectorComponent } from './components/booking-type-selector/booking-type-selector.component';
import { ItineraryV2FlightsComponent } from './components/itinerary-v2-flights/itinerary-v2-flights.component';
import { PaymentMethodV2Component } from './components/payment-method-v2/payment-method-v2.component';
import { CreditCardLogoComponent } from './components/credit-card-logo/credit-card-logo.component';
import { RadarTableModule } from './components/radar-table/radar-table.module';
import { IconsModule } from '@app/icons/icons.module';
import { CreditCardPreviewComponent } from './components/credit-card-preview/credit-card-preview.component';
import { PaymentCreditCardV2Component } from './components/payment-credit-card-v2/payment-credit-card-v2.component';
import { SearchTaskCommentsComponent } from './components/search-task-comments/search-task-comments.component';
import { FirstSystemCommentComponent } from './components/first-system-comment/first-system-comment.component';
import { SearchTaskReopenPanelComponent } from './components/search-task-reopen-panel/search-task-reopen-panel.component';
import { SearchTaskShowedReasonsPanelPipe } from './pipes/search-task-showed-reasons-panel.pipe';
import { SearchTaskShowedReopenPanelPipe } from './pipes/search-task-showed-reopen-panel.pipe';
import { BookingCardComponent } from './components/booking-card/booking-card.component';
import { OrderBookingCostSectionV2Component } from './components/order-booking-cost-section-v2/order-booking-cost-section-v2.component';
import { OrderBookingCostV2Component } from './components/order-booking-cost-v2/order-booking-cost-v2.component';
import { OrderNoteFormV2Component } from './forms/order-note-form-v2/order-note-form-v2.component';
import { FlightRequestFlightFormV2Component } from './forms/flight-request-flight-form-v2/flight-request-flight-form-v2.component';
import { OrderBookingCostFormComponent } from './forms/order-booking-cost-form/order-booking-cost-form.component';
import { FormlyModule } from '@ngx-formly/core';
import { BookingRevenueHeadComponent } from './components/booking-revenue-head/booking-revenue-head.component';
import { BookingMilesHeadComponent } from './components/booking-miles-head/booking-miles-head.component';
import { SellingPriceFormComponent } from './forms/selling-price-form/selling-price-form.component';
import { IMaskModule } from 'angular-imask';
import { UserReassignModalComponent } from './components/user-reassign-modal/user-reassign-modal.component';
import { ContactFormComponent } from './forms/contact-form/contact-form.component';
import { DueDateFormV3Component } from './forms/due-date-form-v3/due-date-form-v3.component';
import { PassengerFormV2Component } from './forms/passenger-form-v2/passenger-form-v2.component';
import { DueDateReminderV2Component } from './components/due-date-reminder-v2/due-date-reminder-v2.component';
import { PassengerDetailsCardComponent } from './components/passenger-details-card/passenger-details-card.component';
import { PassengersSectionComponent } from './components/passengers-section/passengers-section.component';
import { BookingHotelFormComponent } from './forms/booking-hotel-form/booking-hotel-form.component';
import { HotelItineraryComponent } from './components/hotel-itinerary/hotel-itinerary.component';
import { InteractionsModule } from './components/interactions/interactions.module';
import { OrderClientV2Component } from './components/order-client-v2/order-client-v2.component';
import { ClientOrdersSectionComponent } from './components/client-orders-section/client-orders-section.component';
import { ClientRequestsSectionComponent } from './components/client-requests-section/client-requests-section.component';
import { TripNotesV2Component } from './components/trip-notes-v2/trip-notes-v2.component';
import { FlightRequestTripNotesFormComponent } from './forms/flight-request-trip-notes-form/flight-request-trip-notes-form.component';
import { CurrentItineraryComponent } from './components/current-itinerary/current-itinerary.component';
import { OrderBookingConfirmationSectionComponent } from './components/order-booking-confirmation-section/order-booking-confirmation-section.component';
import { OrderBookingEticketsSectionComponent } from './components/order-booking-etickets-section/order-booking-etickets-section.component';
import { ConfirmationFormComponent } from './forms/confirmation-form/confirmation-form.component';
import { ETicketFormComponent } from './forms/e-ticket-form/e-ticket-form.component';

const declarations = [
  RadarEditableFocusDirective,
  RadarOnEnterDirective,
  RadarOnEscapeDirective,
  PageHeaderComponent,
  AnchorNavigationComponent,
  BookingStatusBadgeComponent,
  AnchorItemComponent,
  BookingTypeBadgeComponent,
  ShortFlightInfoComponent,
  PaymentMethodComponent,
  RightPaneTabsComponent,
  HistoryComponent,
  TeamdeskLinkComponent,
  EmailMessagesComponent,
  EmailMessageComponent,
  InvoiceNotesComponent,
  LeadNotesComponent,
  MilesAccountNotesComponent,
  CostsTableCellComponent,
  SupportRequestsSectionComponent,
  UserReassignModalComponent,
  FormButtonStateDirective,
  CommunicationContainerComponent,
  CommunicationContainerNotesComponent,
  ClientCardPreviewComponent,
  UserAssignButtonComponent,
  GroupedUsersFilterPipe,
  BookingTypeSelectorComponent,
  LessThenZeroPipe,
  ItineraryV2FlightsComponent,
  CreditCardLogoComponent,
  PaymentMethodV2Component,
  CreditCardPreviewComponent,
  PaymentCreditCardV2Component,
  SearchTaskCommentsComponent,
  FirstSystemCommentComponent,
  BookingCardComponent,
  OrderBookingCostSectionV2Component,
  OrderBookingCostV2Component,
  OrderNoteFormV2Component,
  FlightRequestFlightFormV2Component,
  OrderBookingCostFormComponent,
  BookingRevenueHeadComponent,
  BookingMilesHeadComponent,
  SellingPriceFormComponent,
  SearchTaskReopenPanelComponent,
  SearchTaskShowedReasonsPanelPipe,
  SearchTaskShowedReopenPanelPipe,
  ContactFormComponent,
  DueDateFormV3Component,
  PassengerFormV2Component,
  DueDateReminderV2Component,
  PassengerDetailsCardComponent,
  PassengersSectionComponent,
  BookingHotelFormComponent,
  HotelItineraryComponent,
  OrderClientV2Component,
  ClientOrdersSectionComponent,
  ClientRequestsSectionComponent,
  TripNotesV2Component,
  FlightRequestTripNotesFormComponent,
  CurrentItineraryComponent,
  OrderBookingEticketsSectionComponent,
  OrderBookingConfirmationSectionComponent,
  ETicketFormComponent,
  ConfirmationFormComponent,
];

@NgModule({
  providers: [EventService],
  declarations: [declarations],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule,
    UiModule,
    UiV2Module,
    NgxDatatableModule,
    RouterModule,
    PipesModule,
    TooltipModule,
    IconsModule,
    RadarTableModule,
    IMaskModule,
    InteractionsModule,
  ],
  exports: [declarations, RadarTableModule, InteractionsModule],
})
export class SharedModule {}
