<div class="d-flex align-items-center v2-mb-2">
  <typography-v2 look="text-semibold" data-id="title">E-Tickets</typography-v2>

  <button-icon-v2
    *ngIf="!readonly"
    [modalTpl]="createEticketTemplate"
    showModalOnClick
    key="plus-v-2"
    size="md"
    color="var(--color-black)"
    class="create-eticket v2-ml-1"
  ></button-icon-v2>
</div>

<div *ngIf="eTickets?.length; else emptyState" class="d-flex etickets-cards">
  <div
    *ngFor="let eTicket of eTickets"
    class="d-flex flex-column v2-p-3 eticket-card position-relative"
    data-id="ticket"
  >
    <button-icon-v2
      *ngIf="!readonly"
      (click)="editETicket(eTicket, editEticketTemplate)"
      key="edit-pen"
      size="md"
      class="position-absolute edit"
      data-id="edit-у-ешслуе"
    ></button-icon-v2>

    <typography-v2
      [tooltip]="eTicket.passenger?.fullName"
      container="body"
      dataId="pax"
      class="text-truncate"
      >{{ eTicket.passenger?.fullName || '&nbsp;' }}</typography-v2
    >
    <div class="d-flex align-items-center justify-content-between info">
      <typography-v2 dataId="code" class="text-truncate">{{ eTicket.codeNumber }}</typography-v2>
      <ng-container *hideForRole="UserType.SalesAgent">
        <button-icon-v2
          *ngIf="eTicket.fileETicket"
          (click)="$event.stopPropagation(); downloadETicket(eTicket.fileETicket.id)"
          key="download"
          size="md"
          color="var(--color-black)"
        ></button-icon-v2>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #emptyState>
  <typography-v2 class="text-black-50">This booking has no e-tickets yet</typography-v2>
</ng-template>

<ng-template #createEticketTemplate>
  <modal-v2 header="New E-Ticket">
    <e-ticket-form (formSubmit)="saveETicket($event)" [orderId]="orderId"></e-ticket-form>
  </modal-v2>
</ng-template>

<ng-template #editEticketTemplate>
  <modal-v2 header="Edit E-Ticket">
    <e-ticket-form
      (formSubmit)="saveETicket($event)"
      (deleteETicket)="deleteETicket(editableETicket?.id)"
      [allowDelete]="true"
      [entityId]="editableETicket?.id"
      [data]="editableETicket"
      [orderId]="orderId"
    ></e-ticket-form>
  </modal-v2>
</ng-template>
