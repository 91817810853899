import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CostNode, OrderNode } from '@app/generated/graphql';
import { BookingType } from '@app/shared/models/booking-type.enum';
import { GraphqlID } from '@app/shared/types';

@Component({
  selector: 'order-booking-cost-section-v2',
  templateUrl: './order-booking-cost-section-v2.component.html',
  styleUrls: ['./order-booking-cost-section-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderBookingCostSectionV2Component {
  @Input() orderId: GraphqlID;
  @Input() bookingId: GraphqlID;
  @Input() bookingType: BookingType;
  @Input() costs?: CostNode[];
  @Input() readonly: boolean;
  @Input() readonlyForTicketing: boolean;
  @Input() supplierFromBooking?: string;
  @Input() order?: OrderNode;
  @Input() withDirect: boolean;

  @Output() bookingCostUpserted = new EventEmitter<CostNode>();
  @Output() bookingCostDeleted = new EventEmitter<string>();
}
