<div class="d-flex align-items-center v2-mb-2">
  <typography-v2 look="text-semibold" data-id="title">Confirmation Numbers</typography-v2>

  <button-icon-v2
    *ngIf="!readonly"
    [modalTpl]="createConfirmationTemplate"
    showModalOnClick
    key="plus-v-2"
    size="md"
    color="var(--color-black)"
    class="create-confirmation v2-ml-1"
  ></button-icon-v2>
</div>

<div *ngIf="airlineConfirmations?.length; else emptyState" class="d-flex flex-column">
  <div
    *ngFor="let airlineConfirmation of airlineConfirmations; let last = last"
    (click)="updatePayment(airlineConfirmation, editConfirmationTemplate)"
    [ngClass]="{ 'v2-mb-2': !last, 'cursor-pointer': !readonly }"
    class="d-flex align-items-center"
    data-id="confirmation-card"
  >
    <airline-logo [logoUrl]="airlineConfirmation.airline?.logoUrl"></airline-logo>

    <ng-container
      *ngIf="(airlineConfirmation.airline?.shortName || airlineConfirmation.airline?.displayName) as name"
    >
      <typography-v2 class="v2-ml-3 text-truncate airline" dataId="airline">
        {{ airlineConfirmation.airline?.shortName || airlineConfirmation.airline?.displayName }}
      </typography-v2>

      <span *ngIf="name &&  airlineConfirmation.code" class="v2-mx-3">&middot;</span>
    </ng-container>

    <typography-v2 dataId="code">{{ airlineConfirmation.code }}</typography-v2>
  </div>
</div>

<ng-template #emptyState>
  <typography-v2 class="text-black-50">The booking has no confirmations yet</typography-v2>
</ng-template>

<ng-template #createConfirmationTemplate>
  <modal-v2 header="New Confirmation">
    <confirmation-form (formSubmit)="createAirlineConfirmation($event)"></confirmation-form>
  </modal-v2>
</ng-template>

<ng-template #editConfirmationTemplate>
  <modal-v2 header="Edit Confirmation">
    <confirmation-form
      (formSubmit)="updateAirlineConfirmation($event)"
      (deleteConfirmation)="deleteAirlineConfirmation(editableAirlineConfirmation?.id)"
      [allowDelete]="true"
      [entityId]="editableAirlineConfirmation?.id"
      [data]="editableAirlineConfirmation"
    ></confirmation-form>
  </modal-v2>
</ng-template>
